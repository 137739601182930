<template>

    <Head :title="$t('homePage').head" />

    <FrontPageLayout>
        <template #mainContent>
            <!-- PRIORITIZE ABOVE THE FOLD CONTENT -->
            <LazyShow>
                <Card class="w-full bg-indigo-100 dark:bg-gray-800 dark:text-indigo-400">
                    <template #title>
                        <h1 class="text-xl font-semibold leading-tight text-center text-indigo-600 md:text-2xl dark:text-indigo-400"
                            v-html="$t('homePage').title">
                        </h1>
                    </template>

                    <template #content>
                        <div class="grid gap-3 md:gap-6 md:grid-cols-2">
                            <Card class="shadow-md">
                                <template #content>
                                    <p class="text-base leading-loose md:text-lg" v-html="$t('homePage').card1"></p>
                                </template>
                            </Card>

                            <Card class="shadow-md">
                                <template #content>
                                    <p class="text-base leading-loose md:text-lg" v-html="$t('homePage').card2"></p>
                                </template>
                            </Card>
                        </div>

                        <Card class="mt-4 shadow-md">
                            <template #title>
                                <p v-html="$t('homePage').body1"
                                    class="text-center text-indigo-600 uppercase dark:text-indigo-400" />
                            </template>
                            <template #content>
                                <p class="text-base leading-loose md:text-lg" v-html="$t('homePage').body2"></p>
                            </template>
                            <template #footer>
                                <Button :label="value ? $t('homePage').showHide : $t('homePage').showHow"
                                    severity="primary" outlined
                                    class="w-full font-semibold text-indigo-600 uppercase dark:text-indigo-400 md:text-lg"
                                    @click="toggle()" />
                            </template>
                        </Card>
                    </template>
                </Card>
            </LazyShow>
            <!-- LAZY LOAD BELOW THE FOLD CONTENT -->
            <LazyShow v-show="value">
                <Card class="w-full mt-4 bg-indigo-100 dark:bg-gray-800 dark:text-indigo-400">
                    <template #title>
                        <h1 class="text-xl font-semibold leading-tight text-center text-indigo-600 md:text-2xl dark:text-indigo-400"
                            v-html="$t('homePage').work">
                        </h1>
                    </template>
                    <template #content>
                        <div class="grid gap-3 md:gap-6">
                            <Card class="shadow-md">
                                <template #content>
                                    <p class="text-base leading-loose md:text-lg" v-html="$t('homePage').workText"></p>
                                </template>
                            </Card>

                            <Card class="shadow-md">
                                <template #content>
                                    <p class="text-base leading-loose md:text-lg" v-html="$t('homePage').info"></p>
                                    <Accordion expandIcon="pi pi-angle-down" collapseIcon="pi pi-angle-up">
                                        <AccordionPanel v-for="n in 5" :key="n" :value="n">
                                            <AccordionHeader class="text-sm md:text-lg">
                                                <strong>{{ $t('homePage')['a' + n + 'Title'] }}</strong>
                                            </AccordionHeader>
                                            <AccordionContent>
                                                <p class="leading-6 text-pretty"
                                                    v-html="$t('homePage')['a' + n + 'Content']"></p>
                                            </AccordionContent>
                                        </AccordionPanel>
                                    </Accordion>
                                    <p class="mt-4 text-base leading-loose md:text-lg" v-html="$t('homePage').faq"></p>
                                </template>
                            </Card>
                        </div>
                    </template>
                </Card>
            </LazyShow>
        </template>
    </FrontPageLayout>
</template>

<script setup>
import { useToggle } from '@vueuse/core';
import { defineAsyncComponent } from "vue";
const FrontPageLayout = defineAsyncComponent(() => import('@/Layouts/FrontPageLayout.vue'));

// Lazy load components that are below the fold
const LazyShow = defineAsyncComponent(() => import('@/Components/LazyShow.vue'));
const [value, toggle] = useToggle();
</script>
